import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const MobileWidgets = () => {
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const { t } = useTranslation();
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          {bussinessData && Object.keys(bussinessData).length !== 0 && (
            <ul className="header-contact-info__list">
              <li>
                <i className="fa fa-phone"></i>{" "}
                <a  href={`tel://${bussinessData.site_settings?.siteCallUsPhoneNumber}`}>
                  <span dir="ltr">
                  {bussinessData.site_settings?.siteCallUsPhoneNumber}
                  </span>
                  </a>
              </li>
              {/* <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:info@yourdomain.com">info@yourdomain.com</a>
            </li> */}
            </ul>
          )}
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}

      <div className="off-canvas-widget-social">
        {bussinessData?.socialLinks?.fb && (
          <a href={bussinessData?.socialLinks?.fb} title="Facebook">
            <i className="fa fa-facebook"></i>
          </a>
        )}
        {bussinessData?.socialLinks?.insta && (
          <a href={bussinessData?.socialLinks?.insta} title="Instagram">
            <i className="fa fa-instagram"></i>
          </a>
        )}
        {bussinessData?.socialLinks?.tiktok && (
          <a href={bussinessData?.socialLinks?.tiktok} title="tiktok">
            <i class="fa fa-tiktok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="17"
                height="17"
                viewBox="0 0 16 16"
              >
                <path d="M 3.5 2 C 2.6774686 2 2 2.6774686 2 3.5 L 2 12.5 C 2 13.322531 2.6774686 14 3.5 14 L 12.5 14 C 13.322531 14 14 13.322531 14 12.5 L 14 3.5 C 14 2.6774686 13.322531 2 12.5 2 L 3.5 2 z M 3.5 3 L 12.5 3 C 12.781469 3 13 3.2185314 13 3.5 L 13 12.5 C 13 12.781469 12.781469 13 12.5 13 L 3.5 13 C 3.2185314 13 3 12.781469 3 12.5 L 3 3.5 C 3 3.2185314 3.2185314 3 3.5 3 z M 8 4 L 8 9.5 C 8 10.33 7.33 11 6.5 11 C 5.67 11 5 10.33 5 9.5 C 5 8.67 5.67 8 6.5 8 C 6.68 8 6.84 8.0298438 7 8.0898438 L 7 7.0507812 C 6.84 7.0207812 6.67 7 6.5 7 C 5.12 7 4 8.12 4 9.5 C 4 10.88 5.12 12 6.5 12 C 7.88 12 9 10.88 9 9.5 L 9 6.2109375 C 9.165316 6.3496799 9.2903403 6.5561561 9.4804688 6.6425781 C 10.313461 7.021211 11.25 7 12 7 L 12 6 C 11.25 6 10.436539 5.978789 9.8945312 5.7324219 C 9.3525237 5.4860548 9 5.1166667 9 4 L 8 4 z"></path>
              </svg>
            </i>
          </a>
        )}
        {bussinessData?.socialLinks?.whatsapp && (
          <a href={bussinessData?.socialLinks?.whatsapp} title="Facebook">
            <i className="fa fa-whatsapp"></i>
          </a>
        )}
      </div>
      {/*Off Canvas Widget Social End*/}
      <br/>
      <br/>
      <br/>
      <br/>
      
     
    </div>
  );
};

export default MobileWidgets;
