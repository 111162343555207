
    //  const URL_BASE_RJS = 'https://adminv1.meemorder.io/api/rjs/';
     const URL_BASE_RJS = 't/';
    //  /set/customer/main/address?
     export const apiVersion = 1;
     const URL_CONSTANTS = new Map([
        ["URL_Get_Home", URL_BASE_RJS +"home"],
        ["URL_Get_Products", URL_BASE_RJS +"products"],
        ["URL_Get_Categories", URL_BASE_RJS +"categories"],
        ['URL_GET_TOKEN','rjs/business/by/name/'],
        ["URL_Get_HomePageAllData", URL_BASE_RJS +"business"],
        ["URL_PRODUCTS_CATEGORY", URL_BASE_RJS+'products/by/category'],
        ["URL_PRODUCTS_CATEGORY_FILTER", URL_BASE_RJS+'product/by/filters'],
        ['URL_GET_PRODUCT',URL_BASE_RJS+"product/by/id"],
        ['URL_PLACE_ORDER',URL_BASE_RJS+'place/order'],
        ['URL_EXTERNAL_PAGES',URL_BASE_RJS+'page?slug='],
        ["URL_PRODUCTS_CATEGORY_FILTER_DYNAMIC", URL_BASE_RJS+'search/product/by/filters'],
        ['URL_Search_Data',URL_BASE_RJS+'search/data'],
        ['URL_ENTER_REVIEW',URL_BASE_RJS+'save/customer/view'],
        // search/product/by/filters
        // URL_Get_Products
        // 
        // categories
        // products
       
       ]);
       export  {URL_CONSTANTS};
    