import PropTypes from "prop-types";
import clsx from "clsx";
import Swiper, { SwiperSlide } from "../../components/swiper";
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle";
import React, { useEffect } from "react";
import { HomepageBrand } from "../../shared/commonUtils";

const settings = {
  // loop: true,
  autoplay: true,
  grabCursor: true,
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 4,
    },
  },
};
interface IBrandLogoSliderOne {
  spaceBottomClass: any;
  spaceTopClass: any;
  homePageBrandLogo: HomepageBrand[];
}
const BrandLogoSliderOne: React.FC<IBrandLogoSliderOne> = (
  props: IBrandLogoSliderOne
) => {
  // const {loading,getHomePageBrands,homePageBrandLogo} = useGetHomePage();
  const { spaceBottomClass, spaceTopClass, homePageBrandLogo } = props;
  useEffect(() => {
    console.log(homePageBrandLogo);
    // getHomePageBrands('1','1','home','brands')
  }, [homePageBrandLogo]);
  return (
    <div className={clsx("brand-logo-area", spaceBottomClass, spaceTopClass)}>
      <div className="container">
        <div className="brand-logo-active">
          {homePageBrandLogo && (
            <Swiper options={settings}>
              {homePageBrandLogo.map((single, key) => (
                <SwiperSlide key={key}>
                  <BrandLogoOneSingle data={single} spaceBottomClass="mb-30" />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

BrandLogoSliderOne.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default BrandLogoSliderOne;
