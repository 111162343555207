import React from "react";
import { ReactComponent as IncreaseIcon } from "../../assets/svgs/increaseCircleCart.svg";
import { ReactComponent as DecreaseIcon } from "../../assets/svgs/decreseSvgCart.svg";
interface IProductQuantity {
  onPressIncrease: (cartItem) => void;
  onPressDecrease: (cartItem) => void;
  quantity: number | string;
  itemObject?: any;
}
const ProductQuantity: React.FC<IProductQuantity> = (
  props: IProductQuantity
) => {
  const { onPressIncrease, onPressDecrease, itemObject, quantity } = props;
  return (
    <div className="item-quantity">
      <span onClick={() => onPressIncrease(itemObject)}>
        <IncreaseIcon />
      </span>
      <span className="item-quantity-text"> {quantity}</span>
      <span onClick={() => onPressDecrease(itemObject)}>
        <DecreaseIcon />
      </span>
    </div>
  );
};

export default ProductQuantity;
