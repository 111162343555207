import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetHomeCategory from "../../../hooks/useGetCategories";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxMethods";
import { setBreadCrumCategoryName } from "../../../store/slices/breadCrums";

const MobileNavMenu = () => {
  const { t } = useTranslation();
  const { homePageCategories } = useAppSelector(
    (state) => state.homePageCategories
  );
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   getHomePageCategory();
  // }, []);
  const updateCategoryBreadCrums = (item: any) => {
    dispatch(
      setBreadCrumCategoryName({
        name: item.name,
        id: item.unique_id!,
        slug: item.slug,
      })
    );
  };
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {/* {!homePageCategories && ( */}
      <ul>
        {homePageCategories &&
          homePageCategories.map((item, index) => {
            return (
              <li className="menu-item-has-children">
                <Link
                  onClick={() => {
                    updateCategoryBreadCrums(item);
                  }}
                  to={
                    process.env.PUBLIC_URL +
                    `/collections/${item.slug}/${item.unique_id}`
                  }
                >
                  {item.name}
                </Link>
                {item?.childern?.length > 0 && (
                  <ul className="sub-menu">
                    {item.childern.map((itemChild, index) => {
                      return (
                        <li className="menu-item-has-children">
                          <Link
                            onClick={() => {
                              updateCategoryBreadCrums(itemChild);
                            }}
                            to={
                              process.env.PUBLIC_URL +
                              `/collections/${itemChild.slug}/${itemChild.unique_id}`
                            }
                          >
                            {itemChild.name}
                            {/* {t("home_group_one")} */}
                          </Link>
                          {itemChild.childern.length > 0 && (
                            <ul className="sub-menu">
                              {itemChild.childern.map((itemChild2, index) => {
                                return (
                                  <li>
                                    <Link
                                      onClick={() => {
                                        updateCategoryBreadCrums(itemChild2);
                                      }}
                                      to={
                                        process.env.PUBLIC_URL +
                                        `/collections/${itemChild2.slug}/${itemChild2.unique_id}`
                                      }
                                    >
                                      {itemChild2.name}
                                      {/* {t("home_fashion")} */}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
      </ul>
      {/* )} */}
    </nav>
  );
};

export default MobileNavMenu;
