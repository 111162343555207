import React from 'react';
import ButtonLoader from '../button-loader';
import { Link } from 'react-router-dom';
interface IButtonCommon {
  onPressButton: () => void;
  btnLabelText: string;
  navigationLink?: string | undefined;
  wrapperStyles?: React.CSSProperties;
  loading?: boolean;
}
const ButtonCommon: React.FC<IButtonCommon> = (props: IButtonCommon) => {
  const {
    onPressButton,
    loading,
    btnLabelText,
    wrapperStyles,
    navigationLink,
  } = props;
  return (
    <div style={{ ...wrapperStyles }}>
      <Link to={navigationLink ? navigationLink : ''}>
        <button className="button-common" onClick={onPressButton}>
          {!loading ? (
            <span>{btnLabelText}</span>
          ) : (
            <ButtonLoader height={15} width={15} colorProp="white" />
          )}
        </button>
      </Link>
    </div>
  );
};

export default ButtonCommon;
