import PropTypes, { object } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/useReduxMethods";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { getDiscountPrice } from "../../helpers/product";
import ProductModal from "./ProductModal";
import Rating from "./sub-components/ProductRating";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
// import { addToCompare } from "../../store/slices/compare-slice";
import { Product } from "../../shared/commonUtils";
import {
  getUserPrefersLang,
  numberWithCommas,
} from "../../shared/helpersMethod";
import HeartEmpty from "../../assets/svgs/HeartEmpty";
import HeartFill from "../../assets/svgs/HeartFill";
import useTranslationHook from "../../hooks/useTranslationHook";
interface IProductGridSingleThree {
  product: Product;
  currency: any;
  cartItem: any;
  wishlistItem: any;
  compareItem: any;
  spaceBottomClass: any;
}
const ProductGridSingleThree: React.FC<IProductGridSingleThree> = (
  props: IProductGridSingleThree
) => {
  const {
    product,
    currency,
    cartItem,
    wishlistItem,
    compareItem,
    spaceBottomClass,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslationHook();
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(
    parseFloat(product.price) * currency.currencyRate
  ).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice !== null ? discountedPrice * currency.currencyRate : 0
  ).toFixed(2);
  const userLng = getUserPrefersLang();
  const dispatch = useDispatch();
  const redirectionLink = `/product/${product.slug}/${product.id}`;

  return (
    <Fragment>
      <div
        className={clsx("product-wrap-2 pro-glaucous-color", spaceBottomClass)}
      >
        <div className="pro-wishlist-2   fav-mobile-button ">
          <button
            className={wishlistItem !== undefined ? "active" : ""}
            disabled={wishlistItem !== undefined}
            title={
              wishlistItem !== undefined
                ? "Added to wishlist"
                : "Add to wishlist"
            }
            onClick={() => dispatch(addToWishlist(product))}
          >
            {wishlistItem !== undefined ? <HeartFill /> : <HeartEmpty />}
            {/* <i className="fa fa-heart-o" /> */}
          </button>
        </div>
        <div className="product-img ">
          <Link to={redirectionLink}>
            {/* <div 
              className="product-list-single"
              style={{backgroundImage:`url(${product.image[0]})`}}
              /> */}
            <img
              className="default-img product-list-single"
              src={process.env.PUBLIC_URL + product.image[0]}
              alt={product.name}
            />
            {/* <div
              className="product-list-single default-img"
              style={{ backgroundImage: `url(${product.image[0]})` }}
            /> */}
            {product?.image?.length > 1 ? (
              <img
                className="product-list-single  hover-img"
                src={process.env.PUBLIC_URL + product.image[1]}
                alt={product.name}
              />
            ) : (
              // <div
              //   className="product-list-single hover-img"
              //   style={{ backgroundImage: `url(${product.image[1]})` }}
              // />
              <img
                className="product-list-single  hover-img"
                src={process.env.PUBLIC_URL + product.image[0]}
                alt={product.name}
              />
              // <div
              //   className="product-list-single hover-img"
              //   style={{ backgroundImage: `url(${product.image[0]})` }}
              // />
            )}
          </Link>

          {/* {[140].includes(bussinessData.businessID) && ( */}
          {product?.brand !== null && (
            <div>
              <img className="brand-logo-img" src={product?.brand?.image} />
            </div>
          )}
          {/* )} */}
          {product.discount !== null || product.new ? (
            <div className="product-img-badges">
              {product.discount !== null ? (
                <span className="pink">
                  -{product.discount.discount_amount}%
                </span>
              ) : (
                ""
              )}
              {product.new ? <span className="purple">New</span> : ""}
            </div>
          ) : (
            ""
          )}
          {/* TODO:: we will remove this section in future once approved nee design  */}
          {/* <div className="product-action-2  ">
            {product.affiliateLink ? (
              <a
                href={product.affiliateLink}
                rel="noopener noreferrer"
                target="_blank"
                title="Buy now"
              >
                {" "}
                <i className="fa fa-shopping-cart"></i>{" "}
              </a>
            ) : product.variations &&
              Object.keys(product.variations).length >= 1 ? (
              <Link to={redirectionLink} title="Select options">
                <i className="fa fa-cog"></i>
              </Link>
            ) : product.stock && product.stock > 0 ? (
              <button
                onClick={() => dispatch(addToCart(product))}
                className={
                  cartItem !== undefined && cartItem.quantity > 0
                    ? "active"
                    : ""
                }
                disabled={cartItem !== undefined && cartItem.quantity > 0}
                title={cartItem !== undefined ? "Added to cart" : "Add to cart"}
              >
                {" "}
                <i className="fa fa-shopping-cart"></i>{" "}
              </button>
            ) : (
              <button disabled className="active" title="Out of stock">
                <i className="fa fa-shopping-cart"></i>
              </button>
            )}

            <Link
              to={redirectionLink}
              // onClick={() => setModalShow(true)} title="Quick View"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </div> */}
        </div>
        <div className="product-content-2">
          <div className="title-price-wrap-2">
            <h2>
              <Link className="text-one-line" to={redirectionLink}>
                {product.name}
              </Link>
            </h2>
            {/* TODO:: we will add in future */}
            {/* <div className="product-model-text ">{"HSU19K-PZFB5BN-INV"}</div> */}
            {/* <div className="product-offer-text d-xl-none">
              <span>
                <OfferSvg />
              </span>
              {"offer available"}
            </div> */}

            <div className="pro-details-rating ">
              <Rating ratingValue={5} />
            </div>
            {product.price != "0" && (
              <div className=" price-2  " style={{ fontSize: 10 }}>
                {discountedPrice !== null ? (
                  <Fragment>
                    <span>
                      {numberWithCommas(finalDiscountedPrice) +
                        " " +
                        currency.currencySymbol}
                    </span>{" "}
                    <span className="old">
                      <span className="mobile-price">
                        {numberWithCommas(finalProductPrice)}
                      </span>
                      <span className="">{currency.currencySymbol}</span>
                    </span>
                  </Fragment>
                ) : (
                  <>
                    <span className="mobile-price">
                      {numberWithCommas(finalProductPrice)}{" "}
                    </span>
                    <span className="mobile-price">
                      {currency.currencySymbol}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {product?.variations.length !== 0 && (
          // <div className="product-view-details ">
          //   <Link to={redirectionLink}>{t("view_details")}</Link>
          // </div>
          <button
            className="product-view-details "
            disabled={cartItem !== undefined && cartItem.quantity > 0}
          >
            <Link to={redirectionLink}>{t("view_details")}</Link>
          </button>
        )}
        {product?.variations.length === 0 && (
          <>
            {product?.isAvailable && product?.isAvailable === "Yes" ? (
              <button
                className="product-view-details "
                disabled={cartItem !== undefined && cartItem.quantity > 0}
                onClick={() => dispatch(addToCart(product))}
              >
                <text>
                  {cartItem !== undefined
                    ? t("txt_added_to_cart")
                    : t("txt_add_to_cart")}
                </text>
              </button>
            ) : (
              <button className="product-view-details" disabled>
                <text>{product?.inventory_un_available_reason[userLng]}</text>
              </button>
            )}
          </>
        )}
      </div>
      {/* product modal */}
      <ProductModal
        productId={product.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment>
  );
};

ProductGridSingleThree.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({}),
};

export default ProductGridSingleThree;
