import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { Footer, ISOCIALLINKS } from "../../shared/commonUtils";
import React, { useEffect, useState } from "react";
interface IFooterOnePROPS {
  backgroundColorClass?: any;
  spaceTopClass?: any;
  spaceBottomClass?: any;
  spaceLeftClass?: any;
  spaceRightClass?: any;
  containerClass?: any;
  extraFooterClass?: any;
  sideMenu?: any;
}
const FooterOne: React.FC<IFooterOnePROPS> = (props: IFooterOnePROPS) => {
  const {
    backgroundColorClass,
    spaceTopClass,
    spaceBottomClass,
    spaceLeftClass,
    spaceRightClass,
    containerClass,
    extraFooterClass,
    sideMenu,
  } = props;
  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  const [footerData, setFooterData] = useState<Footer>(bussinessData?.footer);
  const [socialLinks, setSocialLinks] = useState<ISOCIALLINKS>(
    bussinessData?.socialLinks
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (bussinessData) {
      setFooterData(bussinessData.footer);
      setSocialLinks(bussinessData?.socialLinks);
    }
  }, [bussinessData]);
  return (
    <footer
      id="google_translate_element"
      className={clsx(
        "footer-area",
        backgroundColorClass,
        spaceTopClass,
        spaceBottomClass,
        extraFooterClass,
        spaceLeftClass,
        spaceRightClass
      )}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          {Object.keys(bussinessData).length > 0 && (
            <div
              className={`${
                sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
              }`}
            >
              {/* footer copyright */}
              <FooterCopyright
                footerLogo={bussinessData?.site_settings?.siteLogo}
                spaceBottomClass="mb-30"
              />
            </div>
          )}
          {Object.keys(bussinessData).length > 0 &&
            footerData &&
            null !== footerData &&
            Object.entries(footerData.pages).map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                  }`}
                >
                  <div className="footer-widget mb-30 ml-30">
                    <div className="footer-title">
                      {/* Information Link */}
                      {/* <h3> {item[0]}</h3> */}
                      <h3>{t(`${item[0].replace(" ", "_").toLowerCase()}`)}</h3>
                      {/* <h3>ABOUT US</h3> */}
                    </div>
                    <div className="footer-list">
                      <ul>
                        {item[1].map((itemChild, index) => {
                          return (
                            <li>
                              <Link
                                to={process.env.PUBLIC_URL + `/${itemChild.id}`}
                              >
                                {itemChild.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}

          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-50"
              }`}
            >
              <div className="footer-title">
                <h3>{t("follow_us")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  {socialLinks?.fb && (
                    <li>
                      <a
                        href={socialLinks.fb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("facebook")}
                      </a>
                    </li>
                  )}
                  {socialLinks?.tiktok && (
                    <li>
                      <a
                        href={socialLinks.tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("tiktok")}
                      </a>
                    </li>
                  )}
                  {socialLinks?.insta && (
                    <li>
                      <a
                        href={socialLinks.insta}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("instagram")}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
            }`}
          >
            {/* footer newsletter */}
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
